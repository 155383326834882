import React, {useState} from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Col, Row} from 'react-grid-system'

import SlotList from '../../Slot/SlotList'
import {ButtonPrimary, LoadingSpinner, SimpleNote} from "pyrexx-react-library";
import {AvailableRoutes} from "../../../configs/RouteConfig";
import {useMutation} from "@apollo/client";
import {SCHEDULE_APPOINTMENT} from "../../../mutations/ScheduleAppointmentMutation";

const ScheduleSlotSelectionInput = (props) => {
  const {
    savedValues = {},
    saveFormInputs,
    nextStep,
    slots,
    validatedEncryptedApartmentId,
  } = props

  const {t} = useTranslation()
  const navigate = useNavigate()
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: false,
    message: '',
  })

  const schema = Yup.object({
    validatedEncryptedApartmentId: Yup.string().nullable(true),
    timeframeFrom: Yup.string().nullable(true),
    timeframeTo: Yup.string().nullable(true),
    currentSlot: Yup.string().nullable(true),
    isUserAvailable: Yup.bool(),
  })

  const [scheduleAppointment] = useMutation(SCHEDULE_APPOINTMENT)

  const formik = useFormik({
    initialValues: {
      validatedEncryptedApartmentId: '',
      timeframeFrom: '',
      timeframeTo: '',
      currentSlot: savedValues.currentSlot || null,
      isUserAvailable: savedValues.isUserAvailable || true,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setSubmitStatus((prevState) => ({...prevState, loading: true}))
      saveFormInputs(values)

      if (values.validatedEncryptedApartmentId === '') {
        setSubmitStatus({
          loading: false,
          status: 'error',
          message: t(
            'PLEASE SELECT A OPTION',
          ),
        })
      }

      if (!values.isUserAvailable) {
        setSubmitStatus({
          loading: false,
          status: 'success',
          message: t('SUCCESSFULLY COMPLETED'),
        })
        localStorage.setItem('isCompleted', 'yes');
        navigate(AvailableRoutes?.appointmentSchedule?.done)
      } else {
        const appointmentSchedule = {
          firstName: savedValues.firstName,
          lastName: savedValues.lastName,

          street: savedValues.street,
          floor: savedValues.floor,
          zip: savedValues.zip,
          town: savedValues.town,

          validatedEncryptedApartmentId: values.validatedEncryptedApartmentId,

          timeFrame: {
            from: values.timeframeFrom,
            to: values.timeframeTo,
          },
          appointmentId: savedValues.appointmentId

        }

        scheduleAppointment({
          variables: {appointmentSchedule},
          onCompleted: (data) => {
            if (data?.scheduleAppointment?.success) {
              setSubmitStatus({
                loading: false,
                status: 'success',
                message: t('SUCCESSFULLY SUBMITTED'),
              })
              localStorage.setItem('isCompleted', 'yes');
              navigate(nextStep)
            } else {
              setSubmitStatus({
                loading: false,
                status: 'error',
                message: t(
                  'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
                ),
              })
            }
          },
          onError: () => {
            setSubmitStatus({
              loading: false,
              status: 'error',
              message: t(
                'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
              ),
            })
          },
        })

      }
    },
  })

  const handleSlotSelection = (id, from, to, validatedEncryptedApartmentId) => {
    const slotId = id.split('_')[1];

    formik.setFieldValue(
      'validatedEncryptedApartmentId',
      validatedEncryptedApartmentId,
    )
    formik.setFieldValue('timeframeFrom', slots[slotId].from)
    formik.setFieldValue('timeframeTo', slots[slotId].to)
    formik.setFieldValue('currentSlot', id)
    formik.setFieldValue('isUserAvailable', true)
  }

  const handleUnavailability = (validatedEncryptedApartmentId) => {

    formik.setFieldValue(
      'validatedEncryptedApartmentId',
      validatedEncryptedApartmentId,
    )
    formik.setFieldValue('timeframeFrom', null)
    formik.setFieldValue('timeframeTo', null)
    formik.setFieldValue('currentSlot', null)
    formik.setFieldValue('isUserAvailable', false)
  }

  const emptySlotText =
    Array.isArray(slots) && slots.length > 0
      ? t('THERE IS NO RIGHT SLOT FOR ME, PLEASE CONTACT ME SOON.')
      : t(
        'UNFORTUNATELY WE COULD NOT FIND FREE TIME FRAME. WE WILL CONTACT YOU SOON.',
      )

  return (
    <form onSubmit={formik.handleSubmit}>
      {submitStatus.loading && <LoadingSpinner/>}

      <SlotList
        slots={slots}
        validatedEncryptedApartmentId={validatedEncryptedApartmentId}
        handleSlotSelection={handleSlotSelection}
        handleUnavailability={handleUnavailability}
        currentSlot={formik.values.currentSlot}
        isUserAvailable={formik.values.isUserAvailable}
        emptySlotText={emptySlotText}
      />

      <Row align='center' justify='center' style={{'marginTop': '1rem'}}>
        <Col xs='12' align='center'>
          <ButtonPrimary type='submit'>{t('SUBMIT')}</ButtonPrimary>
        </Col>
      </Row>
      <Row justify='center' style={{marginTop: '1rem'}}>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>
    </form>
  )
}

ScheduleSlotSelectionInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      day: PropTypes.string,
      hour: PropTypes.string,
    }),
  ),
  validatedEncryptedApartmentId: PropTypes.string,
}

export default ScheduleSlotSelectionInput
