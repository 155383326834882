import styled, { createGlobalStyle } from 'styled-components'
import { Container,Row } from 'react-grid-system'
import { helperStyles, portalTheme } from 'pyrexx-react-library'


export const StyledContainer = styled(Container)`

`
export const StyledRow = styled(Row)`
    margin-bottom: 1rem;
`

export const StyledConfirmation = styled(Container)`

`
export const StyledAddress = styled.div`

`

export const StyledStreet = styled.div`

`

export const StyledTownZip = styled.div`

`

export const StyledTimeFrame = styled.span`

`

export const StyledTimeFrameTo = styled.span`

`


export const StyledTimeFrameFrom = styled.span`

`

export const StyledName = styled.span`

`

export const StyledWelcome = styled.div`
  white-space: pre-line;
`

export const StyledHeading = styled.h4`
    font-weight: ${portalTheme.font.weight.bold};
    margin: 1rem 0 0 0;
`
