import {
  Done as MissedDone,
  Start as MissedStart,
  Step1 as MissedStep1,
  Step2 as MissedStep2,
  Step3 as MissedStep3,
  Step4 as MissedStep4,
  Step5 as MissedStep5,
} from '../routes/AppointmentMissed/Steps'

import {
  Done as PlanningDone,
  PreStart as PlanningPreStart,
  Start as PlanningStart,
  Step1 as PlanningStep1,
  Step2 as PlanningStep2,
  Step3 as PlanningStep3,
  Step4 as PlanningStep4,
  Step5 as PlanningStep5,
} from '../routes/AppointmentPlanning/Steps'

import {
  Done as ProblemDone,
  Start as ProblemStart,
  Step1 as ProblemStep1,
  Step2 as ProblemStep2,
  Step3 as ProblemStep3,
} from '../routes/AppointmentProblem/Steps'

import {
  Step1 as CheckStep1,
  Step2 as CheckStep2,
  Step3 as CheckStep3,
  TenantInfo,
} from '../routes/AppointmentCheck/Steps'

import {
  InsertTrackingId,
  TrackingResult,
} from '../routes/AppointmentTracking/Steps'

import {
  Start as ScheduleStart,
  Step1 as ScheduleStep1,
  Step2 as ScheduleStep2,
  Done as ScheduleDone,
} from '../routes/AppointmentSchedule/Steps'

export const AvailableRoutes = {
  home: 'home',
  appointment: 'appointment',
  appointmentMissed: {
    index: 'appointment-missed/*',
    root: 'appointment-missed/start',
    steps: [
      { index: 0, slug: 'start', component: MissedStart },
      { index: 1, slug: 'step-1', component: MissedStep1 },
      { index: 2, slug: 'step-2', component: MissedStep2 },
      { index: 3, slug: 'step-3', component: MissedStep3 },
      { index: 4, slug: 'step-4', component: MissedStep4 }, // Fetching
      { index: 5, slug: 'step-5', component: MissedStep5 },
      { index: 6, slug: 'done', component: MissedDone },
    ],
  },
  appointmentPlanning: {
    index: 'appointment-planning/*',
    root: 'appointment-planning/pre-start',
    steps: [
      { index: 0, slug: 'pre-start', component: PlanningPreStart },
      { index: 1, slug: 'start', component: PlanningStart },
      { index: 2, slug: 'step-1', component: PlanningStep1 },
      { index: 3, slug: 'step-2', component: PlanningStep2 },
      { index: 4, slug: 'step-3', component: PlanningStep3 },
      { index: 5, slug: 'step-4', component: PlanningStep4 },
      { index: 6, slug: 'step-5', component: PlanningStep5 },
      { index: 7, slug: 'done', component: PlanningDone },
    ],
  },
  appointmentProblem: {
    index: 'appointment-problem/*',
    root: 'appointment-problem/start',
    steps: [
      { index: 0, slug: 'start', component: ProblemStart },
      { index: 1, slug: 'step-1', component: ProblemStep1 },
      { index: 2, slug: 'step-2', component: ProblemStep2 },
      { index: 3, slug: 'step-3', component: ProblemStep3 },
      { index: 4, slug: 'done', component: ProblemDone },
    ],
  },
  appointmentCheck: {
    index: 'appointment-check/*',
    root: 'appointment-check/step-1',
    steps: [
      { index: 0, slug: 'step-1', component: CheckStep1 },
      { index: 1, slug: 'tenant-info', component: TenantInfo },
      { index: 2, slug: 'step-2', component: CheckStep2 },
      { index: 3, slug: 'step-3', component: CheckStep3 },
    ],
  },
  appointmentTracking: {
    index: 'appointment-tracking/*',
    root: 'appointment-tracking/insert-id',
    steps: [
      { index: 0, slug: 'insert-id', component: InsertTrackingId },
      { index: 1, slug: 'result', component: TrackingResult },
    ],
  },
  appointmentSchedule: {
    index: 'appointment-schedule/*',
    root: 'appointment-schedule/start',
    steps: [
      { index: 0, slug: 'start', component: ScheduleStart },
      { index: 1, slug: 'step-1', component: ScheduleStep1 },
      { index: 2, slug: 'scheduled', component: ScheduleStep2 },
      { index: 3, slug: 'done', component: ScheduleDone },
    ],
    done: '../done'
  },
  help: 'help',
  contact: 'contact',
  faq: 'faq',
  downloadCenter: 'download-center',
  rateUs: 'rate-us',
  newsletter: 'register-newsletter',
  privacy: 'privacy',
  imprint: 'imprint',
  qr: 'qr',
  liveTracking: 'live-tracking',
  livechat: 'chat',
}
