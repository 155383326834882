import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Spinner } from 'pyrexx-react-library'
import ModalPopup from '../ModalPopup/ModalPopup'

import isObjectValid from '../../helper/isObjectValid'
import ScheduleSlotSelectionInput from "../Steps/Inputs/ScheduleSlotSelectionInput";

const GET_FREE_TIMEFRAMES = gql`
  query SlotSelectionContainerById($encryptedAppointmentId: String!) {
    getAppointmentFreeTimeFrames(encryptedAppointmentId: $encryptedAppointmentId) {
      validation {
        success
        systemMessage
      }
      validatedEncryptedApartmentId
      freeTimeFrames {
        from
        to
      }
      serviceCategory
      street
      zip
      town
      floor
      lastName
      firstName
    }
  }
`

const SlotSelectionContainerById = (props) => {
  const { savedValues, saveFormInputs, prevStep, nextStep } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const variables = {
    encryptedAppointmentId: savedValues.appointmentId,
  }

  let errorMessage = ''

  const { data, loading, error } = useQuery(GET_FREE_TIMEFRAMES, {
    variables,
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Spinner />
  if (error) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  const tfs = data?.getAppointmentFreeTimeFrames || {}

  const {
    freeTimeFrames: slots,
    validatedEncryptedApartmentId = '',
    validation,
    street,
    zip,
    town,
    floor,
    firstName,
    lastName
  } = tfs

  savedValues.street = street
  savedValues.zip = zip
  savedValues.town = town
  savedValues.floor = floor
  savedValues.firstName = firstName
  savedValues.lastName = lastName

  if (!errorMessage && !isObjectValid(tfs)) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  if (!errorMessage && !validation) {
    errorMessage = t(
      'SORRY SINCE WE SENT YOU THE MESSAGE ALL APPOINTMENT SLOTS HAVE BEEN BOOKED, WE WILL CONTACT YOU AGAIN',
    )
  }

  if (errorMessage) {
    return (
      <ModalPopup
        isOpen
        showButtonClose={false}
        noteText={<p>{errorMessage}</p>}
        handleModalClose={() => {}}
        onClick={() => navigate('/')}
        buttonText={t('BACK TO HOME')}
      />
    )
  } else {
    return (
      <ScheduleSlotSelectionInput
        savedValues={savedValues}
        saveFormInputs={saveFormInputs}
        prevStep=''
        nextStep={nextStep}
        slots={slots}
        validatedEncryptedApartmentId={validatedEncryptedApartmentId}
      />
    )
  }
}

export default SlotSelectionContainerById
