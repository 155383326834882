import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'

import SlotSelectionContainerById from "../../../../components/Slot/SlotSelectionContainerById";

const Step2 = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isCompleted = localStorage.getItem('isCompleted');
  if(isCompleted==='yes'){
    navigate('/')
  }

  return (
    <StepContainer
      title={t('APPOINTMENT SCHEDULE')}
      description={t('SELECT AVAILABLE SLOT')}
      form={
        <SlotSelectionContainerById
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      }
    />
  )
}

Step2.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Step2
