import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import AppointmentOfferMessage from '../../../../components/Steps/Inputs/AppointmentOfferMessage'

const Start = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  localStorage.setItem('isCompleted', 'no');
  return (
    <StepContainer
      title={t('APPOINTMENT SCHEDULE')}
      description={t('TENANT APPOINTMENT SCHEDULE')}
      form={
        <AppointmentOfferMessage
          prevStep={prevStep}
          nextStep={nextStep}
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
        />
      }
    />
  )
}

Start.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Start
