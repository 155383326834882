import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import {useNavigate} from "react-router-dom";
import {Col, Row} from "react-grid-system";
import {ButtonPrimary, ButtonSecondary, ButtonSelector} from "pyrexx-react-library";
import {useMutation} from "@apollo/client";

import {useFormik} from "formik";
import {
  StyledAddress,
  StyledConfirmation,
  StyledStreet,
  StyledTimeFrame,
  StyledTimeFrameTo,
  StyledTimeFrameFrom,
  StyledTownZip,
  StyledName,
  StyledHeading,
  StyledContainer,
  StyledRow
} from "../../AppointmentSchedule.styles";
import {useAppContext} from "../../../../contexts/AppContext";
import {SCHEDULE_APPOINTMENT} from "../../../../mutations/ScheduleAppointmentMutation";
import Navigator from "../../../../components/Navigator/Navigator";


const Step2 = (props) => {
  const {prevStep, nextStep, saveFormInputs, savedValues} = props
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {currentLanguage} = useAppContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const redirectToHome = () => {
    navigate('/')
  }


  return (
    <StepContainer
      title={t('APPOINTMENT SCHEDULE')}
      description={t('WE ARE CONFIRMING THE APPOINTMENT FOR THE FOLLOWING DATA:')}
      form={
        <StyledContainer>

          <StyledRow justify='center'>
            <Col xs={12} sm={10} md={8} lg={6}>
              <StyledConfirmation>
                <StyledAddress>
                  <StyledHeading>{t("NAME")}</StyledHeading>
                  <StyledName>{savedValues.firstName} {savedValues.lastName} </StyledName>
                </StyledAddress>

                <StyledAddress>
                  <StyledHeading>{t("ADDRESS")}</StyledHeading>
                  <StyledStreet>{savedValues.street}</StyledStreet>
                  <StyledTownZip>{savedValues.zip}, {savedValues.town} </StyledTownZip>
                </StyledAddress>

                <StyledTimeFrame>
                  <StyledHeading>{t('TIMESLOTS')}</StyledHeading>
                  <StyledTimeFrameFrom>{(new Date(savedValues.timeframeFrom)).toLocaleString(currentLanguage)}</StyledTimeFrameFrom> - <StyledTimeFrameTo>{(new Date(savedValues.timeframeTo)).toLocaleString(currentLanguage)}</StyledTimeFrameTo>
                </StyledTimeFrame>
              </StyledConfirmation>
            </Col>
          </StyledRow>
          <StyledRow justify='center'>
            <Col xs={12} sm={10} md={8} lg={6}>
              <ButtonPrimary onClick={redirectToHome}>
                {t('BACK HOME')}
              </ButtonPrimary>
            </Col>
          </StyledRow>
        </StyledContainer>
      }
    />
  )
}

Step2.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Step2
