import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import { AvailableRoutes } from '../../configs/RouteConfig'
import { GenerateSteps } from '../../helper/GenerateSteps'
import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'


const AppointmentSchedule = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_APPOINTMENT_SCHEDULE')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_APPOINTMENT_SCHEDULE')}
        />
      </Helmet>

      <GenerateSteps
        steps={AvailableRoutes.appointmentSchedule.steps}
        currentLanguage={currentLanguage}
      />
    </>
  )
}

export default AppointmentSchedule
