import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'

const ContainerStyled = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};

  padding: 3rem 0 !important;
`

const ImgStyled = styled.img`
  width: 100%;
`

const TextStyled = styled.p`
  text-align: center;
  font-size: 22px;

  margin: 3rem 0;
`

export { ContainerStyled, ImgStyled, TextStyled }
