import React from 'react'
import { Row, Col } from 'react-grid-system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary } from 'pyrexx-react-library'

import { ContainerStyled, ImgStyled, TextStyled } from './Done.styles'
import DoneImg from '../../../../assets/done.png'

const Done = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const redirectToHome = () => {
    navigate('/')
  }

  return (
    <ContainerStyled>
      <Row justify='center'>
        <Col xs='content'>
          <ImgStyled
            src={DoneImg}
            alt='process-complete'
            title='Process Complete'
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <TextStyled>
            {t(
              'THANK YOU FOR THE INFORMATION. WE WILL PROCESS YOUR REQUEST AS QUICKLY AS POSSIBLE.',
            )}
          </TextStyled>
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary onClick={redirectToHome}>
            {t('BACK HOME')}
          </ButtonPrimary>
        </Col>
      </Row>
    </ContainerStyled>
  )
}

export default Done
